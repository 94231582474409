#cartDialogContainer {
  .modal-close {
    position: absolute;
    left: 40px;
    top: 20px;
  }
}
#cart {
  .detail-section {
    padding: 18px 16px;
    border-bottom: 1px solid var(--border-color);
    .heading-section {
      display: flex;
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 16px;
        &:last-child {
          margin-left: auto;
        }
      }
    }
    .details {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 18px;
      }
    }
    .input-section {
      display: flex;
      align-items: center;
      input {
        width: 65px;
        height: 48px;
        text-align: center;
      }
      span {
        margin-left: 21px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        text-align: left;
        color: var(--color-Special);
      }
    }
    .edit {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: left;
      color: var(--color-Special);
    }
  }
  .tip-section {
    padding: 18px 16px;
    border-bottom: 1px solid var(--border-color);
    .heading {
      display: flex;
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        color: var(--color-neutral900);
        &:last-child {
          margin-left: auto;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          color: var(--color-Special);
        }
      }
    }
    .box-section {
      display: flex;
      justify-content: space-between;
      .box {
        height: 64px;
        width: 68px;
        border-radius: 4px;
        border: 1px solid var(--color-neutral900);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.active {
          border: 3px solid var(--color-Special);
          background: rgba(201, 33, 67, 0.1);
        }
        p {
          margin: 0;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          &:last-child {
            font-weight: 400;
          }
        }
      }
    }
  }

  .total-section {
    padding: 18px 16px;
    div {
      display: flex;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 18px;
        &:nth-child(2) {
          margin-left: auto;
        }
      }
      &:last-child,
      &:nth-last-child(2) {
        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
        }
      }
      &:last-child {
        margin-top: 6px;
      }
    }
  }
  .btn {
    margin: 0px 15px 0 15px;
    background: var(--color-Special);
    height: 48px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    width: -webkit-fill-available;
  }
  .passnumb-section {
    height: 83px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    align-items: center;
    padding-left: 16px;
    padding-right: 28px;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);
    svg {
      margin-left: auto;
      color: var(--color-Special);
    }
  }
  .passnumb-section-noBorder{
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    align-items: center;
    padding-left: 16px;
    padding-right: 15px;
    cursor: pointer;
    justify-content: flex-end;
    color: #c92143;
    svg {
      margin-left: auto;
      color: var(--color-Special);
    }
  }
  .tip-Selected {
    background: rgba(201, 33, 67, 0.1);
    border: 3px solid #c92143 !important;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
.applyCouponBtn {
  width: 100% !important;
  height: 50px !important;
  border-radius: 5px !important ;
  font-size: 15px !important;
  font-weight: 500;
  background-color: var(--color-Special);
}
.couponCard {
  font-size: 18px !important;
  font-weight: 500 !important;
  width: 100%;
}
.cardItem {
  padding: none !important;
  display: flex;
  align-items: center !important;
  cursor: pointer;
}

.MuiCardContent-root:last-child {
  padding: 15px !important;
  background-color: rgb(238, 233, 233);
  color: #c92143;
  border-radius: 5px !important ;
}
