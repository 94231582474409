#applyCouponInfo{
    .center-align{
        display: flex;
        align-items: center;
    }
    .MuiDialog-paperFullWidth {
        background: #ffffff;
        border-radius: 20px;
        padding: 32px 16px 21px 16px;
        max-width: 600px;
        .MuiDialogTitle-root {
          padding: 0;
          .MuiTypography-h6 {
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 32px;
          }
        }
        .MuiTypography-h6{
            display: flex !important;
            align-items: center !important;
        }
        .MuiDialogContent-root {
          padding: 0;
          .your-details {
            padding: 0;
          }
          .content {
            padding: 30px 0;
          }
        }
      }

    .modal-close {
        position: absolute;
        left: 20px;
      }
      .modal-error-message {
        color: #c92143;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        padding-bottom: 20px;
      }
    
      #headerText {
        position: inherit;
        margin-left: 80px;
        font-weight: 700;
      }

      .couponCode {
        align-items: center !important;
        margin-top: 25px;
        margin-bottom: 20px;
    }

      .couponCode input {
        width: 100%;
        height: 50px;
        text-align: center;
        border-radius: 5px;
        margin-bottom: 20px;
    }
}