#header {
  .navbar {
    height: 50px;
    background: var(--color-Special);
    padding-left: 0;
    img {
      width: 112px;
      background: var(--color-Special);
      padding-left: 16px;
      padding-right: 16px;
    }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 111px;
    //   width: 0;
    //   height: 0;
    //   border-top: 51px solid white;
    //   border-right: 25px solid transparent;
    // }
  }
  .welcome-section {
    display: none;
  }
}
