:root {
  --color-Special: #c92143;
  --color-Primary: #3aa7d8;
  --color-positive: #068832;
  --color-accent1: #254b5c;
  --color-accent2: #033245;
  --color-neutral0: #fff;
  --color-neutral200: #f5f5f5;
  --color-neutral666: #666666;
  --color-neutral800: #434343;
  --color-neutral900: #000;

  --border-color: #c4c4c4;

  --breakpoint-tablet: 600px;

  --color-accent3: #643ad8b9;
  --color-accent4: #e91c45;
}


//Red
// :root {
//   --color-Special: #c92143;
//   --color-Primary: #58c6d8;
//   --color-positive: #f7758f;
//   --color-accent1: #2d98b1;
//   --color-accent2: #106c81;
//   --color-neutral0: #fff;
//   --color-neutral200: #f5f5f5;
//   --color-neutral666: #666666;
//   --color-neutral800: #434343;
//   --color-neutral900: #000;

//   --border-color: #c4c4c4;

//   --breakpoint-tablet: 600px;

//   --color-accent3: #643ad8b9;
//   --color-accent4: #e91c45;
// }


//blue 
// :root {
//   --color-Special: #3aa7d8;
//   --color-Primary: #77c1e4;
//   --color-positive: #099ee4;
//   --color-accent1: #2d98b1;
//   --color-accent2: #106c81;
//   --color-neutral0: #fff;
//   --color-neutral200: #f5f5f5;
//   --color-neutral666: #666666;
//   --color-neutral800: #434343;
//   --color-neutral900: #000;

//   --border-color: #c4c4c4;

//   --breakpoint-tablet: 600px;

//   --color-accent3: #643ad8b9;
//   --color-accent4: #e91c45;
// }

// //Green
// :root {
//   --color-Special: rgb(36, 211, 94);
//   --color-Primary: #56db83;
//   --color-positive: #47f781;
//   --color-accent1: #c92143;
//   --color-accent2: #d42a4c;
//   --color-neutral0: #fff;
//   --color-neutral200: #f5f5f5;
//   --color-neutral666: #666666;
//   --color-neutral800: #434343;
//   --color-neutral900: #000;

//   --border-color: #c4c4c4;

//   --breakpoint-tablet: 600px;

//   --color-accent3: #643ad8b9;
//   --color-accent4: #e91c45;
// }


// //Yellow
// :root {
//   --color-Special: rgb(228, 225, 38);
//   --color-Primary: rgb(165, 162, 8);
//   --color-positive: rgb(219, 217, 88);
//   --color-accent1: #254b5c;
//   --color-accent2: #033245;
//   --color-neutral0: #fff;
//   --color-neutral200: #f5f5f5;
//   --color-neutral666: #666666;
//   --color-neutral800: #434343;
//   --color-neutral900: #000;

//   --border-color: #c4c4c4;

//   --breakpoint-tablet: 600px;

//   --color-accent3: #643ad8b9;
//   --color-accent4: #e91c45;
// }
